import React, { useCallback, useState } from "react";
import "./index.scss";
import axios from "axios";

const ResetPassword = ({ location }) => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(
        "Las contraseñas deben coincidir"
    );
    const [showSuccess, setShowSuccess] = useState(false);
    const [firstPassword, setFirstPassword] = useState(null);
    const [secondPassword, setSecondPassword] = useState(null);

    const onSubmit = useCallback(() => {
        if (firstPassword === secondPassword) {
            let url =
                "https://quick-app-backend.herokuapp.com/api/auth/reset-password";
            axios
                .post(url, {
                    code, // code contained in the reset link of step 3.
                    password: firstPassword,
                    passwordConfirmation: firstPassword,
                })
                .then((response) => {
                    setShowSuccess(true);
                })
                .catch((error) => {
                    setErrorMessage(error.response.data.error.message);
                    setShowError(true);
                });
        } else {
            setShowError(true);
            setErrorMessage("Las contraseñas deben coincidir");
        }
    }, [firstPassword, secondPassword]);

    return (
        <main className="main-page">
            <div className="reset-password-container">
                <h1>
                    {!showSuccess
                        ? "Restaurar contraseña"
                        : "Contraseña actualizada"}
                </h1>
                {!showSuccess && (
                    <>
                        <input
                            type="password"
                            onChange={(val) => {
                                setFirstPassword(val.target.value);
                            }}
                            placeholder="Nueva contraseña"
                        ></input>
                        <input
                            type="password"
                            onChange={(val) => {
                                setSecondPassword(val.target.value);
                            }}
                            placeholder="Repetir nueva contraseña"
                        ></input>
                        {showError && (
                            <p className="errorMessages">{errorMessage}</p>
                        )}
                        <button className="submit" onClick={onSubmit}>
                            Restaurar contraseña
                        </button>
                    </>
                )}
            </div>
        </main>
    );
};

export default ResetPassword;
